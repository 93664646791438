import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import CSS from "./menu-button.module.scss"

const MenuButton = props => {
  return (
    <AnchorLink to={props.link} title="link" className={CSS.button}>
      {props.title}
    </AnchorLink>
  )
}

export default MenuButton
