import React, { useState } from "react"
import MenuButton from "../components/menu-button"

import CSS from "./header.module.scss"

const Header = () => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <button
        onClick={() => setOpen(!open)}
        className={`${CSS.navToggle} ${open ? CSS.active : null}`}
        id="navToggle"
        aria-label="menu"
      >
        <span className={CSS.top}></span>
        <span className={CSS.middle}></span>
        <span className={CSS.bottom}></span>
      </button>

      <header className={`${CSS.container} ${open ? CSS.open : null}`}>
        <nav
          className={CSS.nav}
          onClick={() => setOpen(false)}
          role="presentation"
        >
          <MenuButton link="/#home" title="Home" />
          <MenuButton link="/#intro" title="Intro" />
          <MenuButton link="/#wines" title="Our Wines" />
          <MenuButton link="/#photos" title="Photos" />
          <MenuButton link="/#vineyard" title="Vineyard" />
          <MenuButton link="/#contact" title="Contact" />
        </nav>

        <div className={CSS.social}>
          <a className={CSS.button} href="/">
            <img
              className={CSS.button__icon}
              src="/social-twitter.svg"
              alt="twitter"
            />
          </a>
          <a className={CSS.button} href="/">
            <img
              className={CSS.button__icon}
              src="/social-facebook.svg"
              alt="facebook"
            />
          </a>
        </div>
      </header>
    </>
  )
}

export default Header
